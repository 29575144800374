import React, { useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';
import { font, theme } from '@src/styles';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Number } from '@src/components/Number';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ADD_LOGGER_SCREENS } from '../../constant';

const styles = StyleSheet.create({
    icon: {
        marginRight: 10,
    },
    text: {
        ...font.normal,
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: 24,
    },
    textBold: {
        ...font.medium,
    },
    titleText: {
        fontSize: 18,
        letterSpacing: 0.15,
        lineHeight: 22,
    },
    titleWrapper: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 16,
    },
    wrapperWarning: {
        backgroundColor: theme.palette.common.notification.warningBg,
        borderRadius: 8,
        marginTop: 16,
        padding: 24,
    },
});

const getTextLandscape = ({ isLandscape, hp }) => ({
    ...(isLandscape && {
        fontSize: hp('2.27%'),
        lineHeight: hp('2.8%'),
    }),
});

const getTitleTextLandscape = ({ isLandscape, hp }) => ({
    ...(isLandscape && {
        fontSize: hp('3%'),
        lineHeight: hp('3.2%'),
    }),
});

const Warning = () => {
    const { t } = useTranslation();
    const { isLandscape, hp, wp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        titleWrapper: {
            ...(isLandscape && {
                marginBottom: hp('1.6%'),
            }),
        },
        wrapperWarning: {
            ...(isLandscape && {
                marginTop: hp('5%'),
                paddingVertical: hp('2%'),
                paddingHorizontal: wp('1.6%'),
            }),
        },
    });

    return (
        <View style={[styles.wrapperWarning, stylesLandscape.wrapperWarning]}>
            <View style={[styles.titleWrapper, stylesLandscape.titleWrapper]}>
                <Ionicons
                    name="warning-outline"
                    size={24}
                    style={styles.icon}
                    color={theme.palette.common.black}
                />
                <Text style={[
                    styles.text,
                    styles.titleText,
                    styles.textBold,
                    getTitleTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('ACTIVATE_LOGGER.TITLE')}
                </Text>
            </View>

            <Text style={[styles.text, getTextLandscape({ isLandscape, hp })]}>
                {t('ACTIVATE_LOGGER.DESCRIPTION_1')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('ACTIVATE_LOGGER.DESCRIPTION_2')}
                </Text>
                {' '}
                {t('ACTIVATE_LOGGER.DESCRIPTION_3')}
            </Text>
        </View>
    );
};

const NumberStep = ({ number, children }) => {
    const { isLandscape, hp } = useScreen();
    const stepSize = isLandscape ? hp('4.2f%') : 40;
    const stepFontSize = isLandscape ? stepSize / 2 : 16;

    const numberStepstyles = StyleSheet.create({
        step: {
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.primary.deepBlue,
            borderRadius: stepSize / 2,
            borderWidth: 1,
            display: 'flex',
            height: stepSize,
            justifyContent: 'center',
            marginRight: 10,
            width: stepSize,
        },
        stepText: {
            ...font.normal,
            color: isLandscape ? theme.palette.primary.deepBlue : theme.palette.primary.darkBlue,
            fontSize: stepFontSize,
            letterSpacing: 0.15,
            lineHeight: stepFontSize,
        },
        textWrapper: {
            width: '82%',
            ...(isLandscape && {
                width: '74%',
            }),
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 16,
        },
    });

    return (
        <View style={numberStepstyles.wrapper}>
            <View style={numberStepstyles.step}>
                <Text style={numberStepstyles.stepText}>
                    {number}
                </Text>
            </View>
            <View style={numberStepstyles.textWrapper}>
                {children}
            </View>
        </View>
    );
};

const NumberSteps = () => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();

    const numberStepsStyles = StyleSheet.create({
        numberStepsWrapper: {
            marginTop: 32,
            ...(isLandscape && {
                marginBottom: 26,
            }),
        },
    });

    return (
        <View style={numberStepsStyles.numberStepsWrapper}>
            <NumberStep number={1}>
                <Text style={[styles.text, getTextLandscape({ isLandscape, hp })]}>
                    <Text style={[
                        styles.text,
                        styles.textBold,
                        getTextLandscape({ isLandscape, hp }),
                    ]}
                    >
                        {t('ACTIVATE_LOGGER.STEP_1_1')}
                    </Text>
                    {' '}
                    {t('ACTIVATE_LOGGER.STEP_1_2')}
                    {' '}
                    <Text style={[
                        styles.text,
                        styles.textBold,
                        getTextLandscape({ isLandscape, hp }),
                    ]}
                    >
                        {t('ACTIVATE_LOGGER.STEP_1_3')}
                    </Text>
                    {' '}
                    {t('ACTIVATE_LOGGER.STEP_1_4')}
                </Text>
            </NumberStep>

            <NumberStep number={2}>
                <Text style={[styles.text, getTextLandscape({ isLandscape, hp })]}>
                    {t('ACTIVATE_LOGGER.STEP_2_1')}
                    {' '}
                    <Text style={[
                        styles.text,
                        styles.textBold,
                        getTextLandscape({ isLandscape, hp }),
                    ]}
                    >
                        {t('ACTIVATE_LOGGER.STEP_2_2')}
                    </Text>
                </Text>
            </NumberStep>

            <NumberStep number={3}>
                <Text style={[styles.text, getTextLandscape({ isLandscape, hp })]}>
                    {t('ACTIVATE_LOGGER.STEP_3_1')}
                    {' '}
                    <Text style={[
                        styles.text,
                        styles.textBold,
                        getTextLandscape({ isLandscape, hp }),
                    ]}
                    >
                        {t('ACTIVATE_LOGGER.STEP_3_2')}
                    </Text>
                    {' '}
                    {t('ACTIVATE_LOGGER.STEP_3_3')}
                </Text>
            </NumberStep>
        </View>
    );
};

export const LoggerActivation = ({ instance }) => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();
    const navigationNoHistory = useNavigationNoHistory();
    const { setLogger } = useContext(AddLoggerContext);

    const customStyles = StyleSheet.create({
        buttonsBlock: {
            ...(isLandscape && {
                paddingTop: hp('2%'),
            }),
        },
        container: {
            ...(isLandscape && {
                paddingTop: hp('4.6%'),
            }),
        },
        number: {
            marginTop: 0,
        },
    });

    return (
        <Layout verticalAlign="top">
            <Container>
                {isLandscape
                    ? (
                        <>
                            <Number style={customStyles.number} type="small">{instance.number}</Number>
                            <Warning />
                        </>
                    )
                    : (
                        <>
                            <Number type="small">{instance.number}</Number>
                            <Warning />
                            <NumberSteps />
                        </>
                    )}
            </Container>

            <Container style={customStyles.container}>
                {isLandscape && (
                    <NumberSteps />
                )}
                <ButtonsBlock
                    style={customStyles.buttonsBlock}
                    primaryBtnText={t('ACTIVATE_LOGGER.CHECK_LOGGER_STATUS')}
                    primaryBtnOnPress={() => {
                        setLogger(cur => ({ ...cur, wasStandBy: true }));
                        navigationNoHistory({ name: ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE });
                    }}
                />
            </Container>
        </Layout>
    );
};
