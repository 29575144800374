/* eslint-disable no-await-in-loop */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { ActivityIndicator, StyleSheet, Text } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { useTranslation } from 'react-i18next';
import { INSTANCE_TYPES, IS_CREATE_ASSET_NO_WARNING } from '@src/constants';
import { getStatus } from '@src/lib';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { useNotification } from '@src/hooks/useNotification';
import { Layout } from '@src/components/Layout';
import { ConfirmModal } from '@src/RemoveLogger/Screens/CheckCode/ConfirmModal';
import { Container } from '@src/components/Layout/Container';
import { Number } from '@src/components/Number';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { font, theme } from '@src/styles';
import { ADD_LOGGER_SCREENS } from '../constant';
import { WrongAssetType } from './partials/WrongAssetType';
import { fixError } from '../AddNewAsset/lib';
import { LoggerTypeSelection } from './partials/LoggerTypeSelection';
import { LoggerActivation } from './partials/LoggerActivation';
import { LoggerOffline } from './partials/LoggerOffline';

const nextScreenAsset = ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE;
const nextScreenLogger = ADD_LOGGER_SCREENS.SHOW_PAIRING_INSTRUCTION;
const backScreenAsset = ADD_LOGGER_SCREENS.ENTER_ASSET_CODE;
const backScreenLogger = ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE;
let wasAddAssetEnvoke = false;
let wasCanceledLoggerStatusCheck = false;

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    route: any,
    type: 'asset' | 'logger',
};

const CheckAssetOrLogger = ({ route, type = 'asset' }: Props) => {
    const { fromScreen } = route.params || {};
    const navigationNoHistory = useNavigationNoHistory();
    const { t } = useTranslation();
    const { showSuccess } = useNotification();
    const { isLandscape, wp, hp } = useScreen();
    const { asset, setAsset, logger, setLogger, stepper } = useContext(AddLoggerContext);
    const [status, setStatus] = useState(null);
    const [loggerChoiceOptions, setLoggerChoiceOptions] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {
        checkPairingByAsset,
        checkPairingByLogger,
        addAsset,
        checkLoggerType,
        getLoggerStatusCheck,
    } = useBackendEndpoints().requests;
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const setInstance = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? setAsset : setLogger),
        [type, setAsset, setLogger],
    );
    const backScreen = useMemo(() => (type === INSTANCE_TYPES.ASSET ? backScreenAsset : backScreenLogger), [type]);
    const nextScreen = useMemo(() => (type === INSTANCE_TYPES.ASSET ? nextScreenAsset : nextScreenLogger), [type]);

    const checkInstance = async () => {
        wasCanceledLoggerStatusCheck = false;

        if (!instance.number) {
            global.console.error('No instance number');
            return;
        }

        let loggerId = null;
        let selectedLoggerType = null;

        if (type === INSTANCE_TYPES.LOGGER && !instance.selectedLoggerType) {
            const resultCheckLoggerType = await checkLoggerType(instance.number);
            const { data } = resultCheckLoggerType;

            if (!data?.resultList) {
                setStatus(STATUSES.ERROR);
                return;
            }

            if (data.resultList.length === 0) {
                setStatus(STATUSES.NOT_FOUND);
                return;
            } else if (data.resultList.length > 1) {
                setLoggerChoiceOptions(data.resultList);
                setStatus(STATUSES.LOGGER_TYPE_SELECT);
                return;
            } else if (data.resultList.length === 1) {
                loggerId = data.resultList[0].id;
                selectedLoggerType = data.resultList[0].loggerType;
                setInstance(curInstance => ({ ...curInstance, selectedLoggerType, id: loggerId }));
            }
        }

        if (type === INSTANCE_TYPES.LOGGER) {
            setStatus(STATUSES.CONNECTING_TO_LOGGER);
            if (!instance.id && !loggerId) {
                global.console.error('No logger id');
                setStatus(STATUSES.ERROR);
                return;
            }

            const resultStatusCheck = await getLoggerStatusCheck(instance.id || loggerId);

            if (resultStatusCheck?.data?.status === 'ACTIVE') {
                setInstance(curInstance => ({ ...curInstance, isLoggerActive: true }));
            } else if (!instance.wasStandBy) {
                setStatus(STATUSES.LOGGER_ACTIVATION);
                return;
            } else if (instance.wasStandBy) {
                let wasActivated = false;

                for (let i = 0; i < 5; i++) {
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise(resolve => setTimeout(resolve, 2000));

                    if (wasCanceledLoggerStatusCheck) return;

                    const resultStatusCheckTmp = await getLoggerStatusCheck(instance.id || loggerId);

                    if (resultStatusCheckTmp?.data?.status === 'ACTIVE') {
                        setInstance(curInstance => ({ ...curInstance, isLoggerActive: true }));
                        wasActivated = true;
                        break;
                    }
                }

                if (!wasActivated) {
                    setStatus(STATUSES.LOGGER_OFFLINE);
                    return;
                }
            } else {
                setStatus(STATUSES.LOGGER_OFFLINE);
                return;
            }
        }

        const result = type === INSTANCE_TYPES.ASSET
            ? await checkPairingByAsset(instance.number)
            : await checkPairingByLogger(instance.number, instance.selectedLoggerType || selectedLoggerType);

        if (result && result.data) {
            const { data } = result;

            if (data.assetTypeCode) {
                data.assetType = data.assetTypeCode; // TODO: delete this after backend assetTypeCode -> assetType
            }

            setInstance(curInstance => ({ ...curInstance, ...data }));
            getStatus({ type, data, setStatus, selectedAssetType: instance.selectedAssetType });
        } else {
            setStatus(STATUSES.ERROR);
        }
    };

    const tryAgain = () => {
        if (!fromScreen?.includes('SCAN')) {
            navigationNoHistory({ name: type === INSTANCE_TYPES.ASSET
                ? ADD_LOGGER_SCREENS.SCAN_ASSET_CODE
                : ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE });
            return;
        }
        navigationNoHistory({ name: fromScreen || backScreen });
    };

    useEffect(() => {
        checkInstance();
    }, [asset?.selectedAssetType]);

    const next = () => {
        stepper.nextStep();
        navigationNoHistory({ name: nextScreen });
        showSuccess(`${instance.number} ${t('ADD_LOGGER.WAS_IDENTIFIED')}`);
    };

    const addAssetHandler = async () => {
        if (wasAddAssetEnvoke) {
            return;
        }
        wasAddAssetEnvoke = true;

        if (type === INSTANCE_TYPES.ASSET && !instance.selectedAssetType) {
            wasAddAssetEnvoke = false;
            navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
            return;
        }

        // TODO: refactor this, it
        // has duplicate code with onConfirm src/AddLogger/Screens/AddNewAsset/AddNewAsset.tsx
        const { number: code, selectedAssetType } = instance;
        const result = await addAsset(code, selectedAssetType);

        if (result?.status === 201) {
        // asset added but we need to get its id
            const assetWithId = await checkPairingByAsset(code);

            if (assetWithId?.status === 200 && assetWithId?.data) {
                const { data } = assetWithId;

                if (data.assetTypeCode) {
                    data.assetType = data.assetTypeCode; // TODO: delete this after backend assetTypeCode -> assetType
                }

                setAsset(curAsset => ({ ...curAsset, ...data, number: code }));
                stepper.nextStep();
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE });
                showSuccess(t('ADD_LOGGER.ASSET_ADDED', { assetNumber: code }));
            } else {
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.ERROR });
            }
        } else if (result?.status === 400) {
            // TODO: fix any
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (result?.message) {
                // TODO: fix any
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const errorMessage = fixError(result?.message);

                setAsset(curAsset => ({ ...curAsset, error: errorMessage }));
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
            }
        } else {
            navigationNoHistory({ name: ADD_LOGGER_SCREENS.ERROR });
        }
    };

    useEffect(() => {
        wasAddAssetEnvoke = false;
    }, []);

    useEffect(() => {
        if (status === STATUSES.IDENTIFIED) {
            next();
        }

        if (status === STATUSES.NOT_FOUND && type === INSTANCE_TYPES.ASSET && !wasAddAssetEnvoke) {
            if (IS_CREATE_ASSET_NO_WARNING) {
                addAssetHandler();
            } else {
                if (!instance.selectedAssetType) {
                    wasAddAssetEnvoke = false;
                    navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
                    return;
                }
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.ADD_NEW_ASSET });
            }
        }
    }, [status]);

    const styles = StyleSheet.create({
        connectingLoggerWrapper: {
            alignItems: 'center',
            flexGrow: 0,
        },
        notFoundContainerLeft: {
            ...(isLandscape && {
                width: '52%',
            }),
        },
        notFoundContainerRight: {
            ...(isLandscape && {
                width: '46%',
            }),
        },
        notFoundWrapper: {
            ...(isLandscape && {
                paddingTop: hp('7.3%'),
            }),
        },
        pairMarginLeft: {
            ...(isLandscape && {
                marginLeft: wp('5%'),
            }),
        },
        pairedContainerLeft: {
            ...(isLandscape && {
                width: '52%',
            }),
        },
        pairedContainerRight: {
            ...(isLandscape && {
                paddingTop: hp('9%'),
                width: '44%',
            }),
        },
        textConnecting: {
            ...font.normal,
            fontSize: hp('2.6%'),
            lineHeight: hp('3%'),
            marginTop: hp('2.6%'),
            textAlign: 'center',
        },
        textConnectingDescription: {
            ...font.normal,
            fontSize: hp('1.9%'),
            lineHeight: hp('2.8%'),
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('2.4%'),
                lineHeight: hp('3.5%'),
            }),
        },
    });

    const onPressNextWrongAssetType = () => {
        setAsset(curAsset => ({ ...curAsset, selectedAssetType: curAsset.assetType }));
    };

    if (status === STATUSES.CONNECTING_TO_LOGGER) {
        return (
            <Layout verticalAlign="center" wrapperStyle={styles.notFoundWrapper}>
                <Container style={[styles.notFoundContainerLeft, styles.connectingLoggerWrapper]} isPaddingTop>
                    <ActivityIndicator
                        size={hp('7%')}
                        color={theme.palette.primary.deepBlue}
                    />
                    <Text style={styles.textConnecting}>{t('ADD_LOGGER.CONNECTING_TO_LOGGER')}</Text>
                </Container>

                <Container style={styles.notFoundContainerRight}>
                    <Number type="small">{instance.number}</Number>
                    <Text style={styles.textConnectingDescription}>
                        {t('ADD_LOGGER.CONNECTING_TO_LOGGER_DESCRIPTION')}
                    </Text>

                    <ButtonsBlock
                        primaryBtnText={t('COMMON.CANCEL')}
                        primaryBtnOnPress={() => {
                            wasCanceledLoggerStatusCheck = true;
                            tryAgain();
                        }}
                    />
                </Container>
            </Layout>
        );
    }

    if (status === STATUSES.LOGGER_ACTIVATION) {
        return (
            <LoggerActivation
                instance={instance}
            />
        );
    }

    if (status === STATUSES.LOGGER_OFFLINE) {
        return (
            <LoggerOffline
                instance={instance}
                primaryBtnOnPress={tryAgain}
            />
        );
    }

    if (status === STATUSES.WRONG_ASSET_TYPE && asset.selectedAssetType) {
        return (
            <WrongAssetType
                instance={instance}
                primaryBtnOnPress={onPressNextWrongAssetType}
                secondaryBtnOnPress={tryAgain}
            />
        );
    }

    if (status === STATUSES.LOGGER_TYPE_SELECT) {
        return (
            <LoggerTypeSelection loggerChoiceOptions={loggerChoiceOptions} />
        );
    }

    if (status === STATUSES.NOT_FOUND && type !== INSTANCE_TYPES.ASSET) {
        return (
            <Layout verticalAlign="center" wrapperStyle={styles.notFoundWrapper}>
                <Container style={styles.notFoundContainerLeft} isPaddingTop>
                    <Status status={STATUSES.NOT_FOUND} instanceType={type} />
                    <Number>{instance.number}</Number>
                </Container>

                <Container style={styles.notFoundContainerRight}>
                    {type === INSTANCE_TYPES.ASSET
                        ? (
                            <ButtonsBlock
                                primaryBtnText={t('ADD_LOGGER.ADD_NEW_ASSET')}
                                primaryBtnOnPress={() => navigationNoHistory({
                                    name: ADD_LOGGER_SCREENS.ADD_NEW_ASSET })}
                                secondaryBtnText={t('COMMON.TRY_AGAIN')}
                                secondaryBtnOnPress={tryAgain}
                            />
                        )
                        : (
                            <ButtonsBlock
                                primaryBtnText={t('COMMON.TRY_AGAIN')}
                                primaryBtnOnPress={tryAgain}
                            />
                        )}
                </Container>
            </Layout>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <Layout
                isWrapperNoPadding={isLandscape}
                verticalAlign="center"
            >
                <Container style={styles.pairedContainerLeft} isPaddingTop>
                    <Status
                        status={STATUSES.PAIRED}
                        assetNumber={instance.assetNumber}
                        loggerNumber={instance.loggerNumber}
                        assetType={asset.assetType}
                        pairStyle={styles.pairMarginLeft}
                    />

                    <ConfirmModal
                        isVisible={isModalVisible}
                        onClose={() => setIsModalVisible(false)}
                        type={type}
                        assetType={asset.assetType}
                        context={AddLoggerContext}
                        successScreen={
                            type === INSTANCE_TYPES.ASSET
                                ? ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE
                                : ADD_LOGGER_SCREENS.SHOW_PAIRING_INSTRUCTION
                        }
                        successMessage={t(
                            'ADD_LOGGER.REMOVAL_SUCCESS',
                            { number: type === INSTANCE_TYPES.ASSET ? instance.assetNumber : instance.loggerNumber },
                        )}
                    />
                </Container>

                <Container style={styles.pairedContainerRight}>
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.REMOVE')}
                        primaryBtnOnPress={() => setIsModalVisible(true)}
                        secondaryBtnText={t('COMMON.BACK')}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </Layout>
        );
    }

    if (status === STATUSES.ERROR) {
        navigationNoHistory({
            name: ADD_LOGGER_SCREENS.ERROR,
            params: { tryAgainScreen:
                fromScreen?.includes('SCAN')
                    ? fromScreen || backScreen
                    : (
                        type === INSTANCE_TYPES.ASSET
                            ? ADD_LOGGER_SCREENS.SCAN_ASSET_CODE
                            : ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE

                    ),
            },
        });
        return null;
    }

    return (
        <SpinnerScreen />
    );
};

export const CheckAsset = (props) => (
    <CheckAssetOrLogger type="asset" {...props} />
);

export const CheckLogger = (props) => (
    <CheckAssetOrLogger type="logger" {...props} />
);
